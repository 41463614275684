export class HtmlView {
  /**
   * @constructor
   * @param {Object} config
   */
  constructor(config) {
    this.scope     = config.scope || null;
    this.selectors = config.selectors || {};
    this.elements  = config.elements || {};
  }

  setScope() {
    this.scope = this.selectors.scope ? document.querySelector(this.selectors.scope) : document.body;
    return this;
  }

  bindElements() {
    let tmpElements = {};
    for (let selector in this.selectors) {
      if (this.selectors.hasOwnProperty(selector) && selector !== 'scope') {
        tmpElements[selector] = Array.prototype.slice.call(this.scope.querySelectorAll(this.selectors[selector]));
      }
    }
    this.elements = tmpElements;
    return this;
  }

  /**
   * tests whether an element is in this.elements
   *
   * @param   {String} key
   * @returns {boolean}
   */
  elementExists(key) {
    let elementExists = false;
    if (this.elements.hasOwnProperty(key)) {
      elementExists = true;
    }
    else {
      for (let element in this.elements) {
        if (this.elements.hasOwnProperty(element)) {
          let current = this.elements[element];
          if (current.id === key || current.classList.contains(key) || current.dataset[key]) {
            elementExists = true;
          }
          else {
            elementExists = false;
          }
        }
      }
    }
    return elementExists;
  }

  /**
   * Disables element to block user interaction
   * @param element
   * @return {HtmlView}
   */
  disable(element) {
    element = element || this.scope;
    element.classList.add('disabled');
    return this;
  }

  /**
   * Hides element from view
   * @param element
   * @return {HtmlView}
   */
  hide(element) {
    element = element || this.scope;
    element.classList.add('hidden');
    return this;
  }

  /**
   * shows element in view
   * @param element
   * @return {HtmlView}
   */
  show(element) {
    element = element || this.scope;
    element.classList.remove('hidden');
    return this;
  }

  init() {
    this.setScope().bindElements();
    return this;
  }
}