import {AjaxModalModel}      from './AjaxModalModel.src.js';
import {AjaxModalView}       from './AjaxModalView.src.js';
import {AjaxModalController} from './AjaxModalController.src.js';

let contactFormModel = new AjaxModalModel({
  data: {url: 'view/view/contact-support.phtml'},
});
let contactFormView  = new AjaxModalView({
  selectors: {
    scope  : 'footer',
    buttons: 'a.btn[data-target="#contactFormModal"]',
  },
});
let contactFormCtrl  = new AjaxModalController(contactFormModel, contactFormView);

contactFormCtrl.init();