import {HttpRequestController}        from './HttpRequestController.src';
import {EntouchPropertiedObjectModel} from './models/EntouchPropertiedObjectModel.src';

/**
 * AjaxModalController - creates a modal with content loaded via ajax
 */
export class AjaxModalController {
  /**
   * AjaxModal - creates a modal with content loaded via ajax
   * @param {AjaxModalModel} model
   * @param {AjaxModalView} view
   * @constructor
   */
  constructor(model, view) {
    this.model       = model;
    this.view        = view;
    this.HttpRequest = HttpRequestController;
  }

  /**
   * @return {AjaxModalController}
   */
  bindViewElements() {
    this.view.init();
    return this;
  }

  /**
   * @return {AjaxModalController}
   */
  bindEvents() {
    this.view.scope.addEventListener('click', this.clickHandler.bind(this));
    this.view.scope.addEventListener('keyup', this.keyboardHandler.bind(this));
    return this;
  }

  /**
   * Default Event
   * @param {Event|MouseEvent|CustomEvent} event
   * @param {HTMLElement} [targetElement]
   */
  defaultHandler(event, targetElement) {
    let elementType       = targetElement.type || targetElement.dataset.type || undefined;
    let handlerMethodName = this.model.getMethodName(elementType, 'Handler');

    if (handlerMethodName !== 'defaultHandler' && this.model.methodExists(handlerMethodName, this)) {
      this[handlerMethodName](event);
    }
  }

  /**
   * Handles Keyboard Input
   * @param {KeyboardEvent} event
   */
  keyboardHandler(event) {

    if (event.target && event.target.required) {
      this.view.renderValidation(this.model.isValidInput(event.target), event.target);
    }
  }

  /**
   * DOM Element Click Handler
   * @param {MouseEvent} event
   * @param {HTMLElement} [targetElement]
   * @return {boolean}
   */
  clickHandler(event, targetElement) {
    targetElement = targetElement || event.target || event.srcElement;
    if (targetElement) {
      let elementType       = targetElement.type || targetElement.dataset.type || undefined;
      let handlerMethodName = this.model.getMethodName(elementType, 'Handler');

      if (this.model.methodExists(handlerMethodName, this)) {
        this[handlerMethodName](event, targetElement);
      }
    }
    else {
      this.defaultHandler(event);
    }

    return false;
  }

  /**
   * Handle <BUTTON> clicks
   * @param {MouseEvent} event
   * @param {HTMLButtonElement} [button]
   */
  buttonHandler(event, button) {
    button      = button || event.target;
    let rawData = button.dataset || {};
    let data    = this.model.setData(rawData).mapData().getData();

    if (data.route) {
      this.routeHandler(data, event);
    }
    else {
      this.view.render();
    }
  }

  /**
   * Handles Form Submission
   * @param event
   * @param button
   */
  submitHandler(event, button) {
    if (event.preventDefault) {
      event.preventDefault();
    }

    let requiredElements = this.view.setRequiredElements(this.view.form).getRequiredElements();
    let validation       = requiredElements.length ? this.model.validate(requiredElements) : {};
    let isValid          = !EntouchPropertiedObjectModel.valueExists(validation, false);

    if (isValid) {

      this.view.hide(button);
      this.view.hide(this.view.form.elements.cancel);

      let httpRequestModel = new this.model.HttpRequest({
        method: 'POST',
        url   : this.view.form.action,
        data  : this.model.setData(this.view.form.elements).mapDataByKey('name').mapFormData().getData()
      });
      let httpRequest      = new this.HttpRequest(httpRequestModel);

      httpRequest.setResponseHandler(this.httpResponseHandler.bind(this))
        .bindEvents()
        .executeRequest();
    }
    else {
      for (let key in requiredElements) {
        if (requiredElements.hasOwnProperty(key)) {
          let element = requiredElements[key];
          this.view.renderValidation(this.model.isValidInput(element), element);
        }
      }
    }

  }

  /**
   * Hooks into predefined custom app routes
   * @param {Object} data
   * @param {Event} event
   */
  routeHandler(data, event) {

    let methodName = this.model.getMethodName(data.route, 'Handler');

    if (this.model.methodExists(methodName, this)) {
      this[methodName](data);
    }
    else {
      this.defaultHandler(event);
    }

  }

  /**
   * XML Http Response Handler
   * @param {String|Array|Object} response
   */
  httpResponseHandler(response) {
    let html = typeof response === typeof '' ? response : '';
    this.view.render(html).bindViewElements();

  }

  /**
   * XML Http Request Handler
   * @param {Object} xhrModel
   */
  httpRequestHandler(xhrModel) {
    let requestModel = new this.model.HttpRequest(xhrModel);
    let request      = new this.HttpRequest(requestModel);

    request.bindEvents()
      .setResponseHandler(this.httpResponseHandler.bind(this))
      .executeRequest();
  }

  /**
   * Init this module
   * @return {AjaxModalController}
   */
  init() {
    this.bindViewElements().bindEvents();
    return this;
  }

}