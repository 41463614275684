/**
 * EntouchPropertiedObjectModel
 */
export class EntouchPropertiedObjectModel {
  /**
   * @constructor
   * @param {Object} config
   */
  constructor(config) {
    this.data     = config.data || {};
    this.dataSize = this.mapData().getDataSize();
  }

  /**
   * gets data
   * @return {*}
   */
  getData() {
    return this.data;
  }

  /**
   * sets data
   * @param {*} newData
   * @return {EntouchPropertiedObjectModel}
   */
  setData(newData) {
    this.data = newData;
    return this;
  }

  /**
   * Add named property and value
   * @param {Object|Array} newDataObj
   * @param {String} [prop]
   * @return {EntouchPropertiedObjectModel}
   */
  addData(newDataObj, prop) {
    if (prop) {
      this.data[prop] = newDataObj;
    }
    else {
      for (let prop in newDataObj) {
        if (newDataObj.hasOwnProperty(prop)) {
          this.data[prop] = newDataObj[prop];
        }
      }
    }
    return this;
  }

  /**
   * Map data as predefined by existing keys
   * @return {EntouchPropertiedObjectModel}
   */
  mapData() {
    let tmpData = {};
    for (let prop in this.data) {
      if (this.data.hasOwnProperty(prop)) {
        tmpData[prop] = this.data[prop];
      }
    }
    this.data = tmpData;
    return this;
  }

  /**
   * Map data by key if found
   * @param {String|Number} key
   * @return {EntouchPropertiedObjectModel}
   */
  mapDataByKey(key) {
    let tmpData = {};
    for (let prop in this.data) {
      if (this.data.hasOwnProperty(prop) && this.data[prop][key] !== undefined) {
        let newKey      = this.data[prop][key];
        tmpData[newKey] = this.data[prop].value;
      }
    }
    this.data = tmpData;
    return this;
  }

  /**
   * get count of top level properties
   * @return {number}
   */
  getDataSize() {
    return Object.keys(this.data).length;
  }

  /**
   * get first data property
   * @return {*}
   */
  getFirst() {
    return this.data[Object.keys(this.data)[0]];
  }

  /**
   * get last data property
   * @return {*}
   */
  getLast() {
    return this.data[Object.keys(this.data)[Object.keys(this.data).length]];
  }

  /**
   * Produces a method name
   * @param {String} entityName
   * @param {String} [postfix]
   * @param {String} [prefix]
   * @return {String}
   */
  getMethodName(entityName, postfix, prefix) {
    entityName = entityName || 'default';
    prefix     = prefix || '';
    postfix    = postfix || '';

    return prefix.toString() + entityName.toString() + postfix.toString();
  }

  /**
   * Does this entity have a particular method?
   * @param {String} methodName
   * @param {Object} entity
   * @return {boolean}
   */
  methodExists(methodName, entity) {
    return entity.hasOwnProperty(methodName) || entity.__proto__.hasOwnProperty(methodName);
  }

  /**
   * Tests if an object contains a given value
   * @param {Object} entity
   * @param {*} value
   * @return {boolean}
   */
  static valueExists(entity, value) {
    for (var prop in entity) {
      if (entity.hasOwnProperty(prop) && entity[prop] === value) {
        return true;
      }
    }
  }
}
