import {FormView} from './views/FormView';

/**
 * EntouchModalView
 *
 */
export class AjaxModalView extends FormView {
  /**
   * @param {Object} config
   * @property {Array<HTMLInputElement>} inputs
   * @property {String} inputSelector
   * @property {HTMLElement} [output]
   * @property {Number} width
   */
  constructor(config) {
    super(config);
    this.inputs        = [];
    this.inputSelector = config.inputSelector || 'button';
    this.output        = config.output || null;
    this.width         = config.width || 940;
  }

  /**
   * Map all buttons as inputs
   * @return {AjaxModalView}
   */
  bindViewElements() {
    // Bind Modal View Inputs
    this.inputs = Array.prototype.slice.call(this.output.querySelectorAll(this.inputSelector));
    return this;
  }

  /**
   * Render Validation results
   * @param isValid
   * @param element
   */
  renderValidation(isValid, element) {
    this.toggleError(element, isValid);
    return this;
  }

  /**
   * Render HTML
   * @param {string} html
   * @return {AjaxModalView}
   */
  render(html) {
    if (this.output === null) {
      this.output                  = document.createElement('div');
      this.output.dataset.role     = 'modal-window';
      this.output.style.width      = this.width + 'px';
      this.output.style.marginLeft = -(this.width / 2) + 'px';
      this.output.classList.add('modal');

      this.hide(this.output);

      this.scope.appendChild(this.output);
    }
    if (html) {
      this.output.innerHTML = html;
      this.setForm(this.output).show(this.output);
    }
    else {
      this.hide(this.output);
    }

    return this;
  }

  init() {
    super.init();
  }
}
