import {EntouchPropertiedObjectModel} from './EntouchPropertiedObjectModel.src';

export class FormModel extends EntouchPropertiedObjectModel {
  /**
   * @constructor
   * @param {Object} [config]
   */
  constructor(config) {
    super(config);
    this.data = config.data || {};
  }

  getData() {
    return this.data;
  }

  /**
   * Maps data as FormData object
   * @return {FormModel}
   */
  mapFormData(data) {
    data         = data || this.data || {};
    let formData = new FormData();
    for (let prop in data) {
      if (data.hasOwnProperty(prop)) {
        formData.append(prop, data[prop]);
      }
    }
    this.data = formData;
    return this;
  }

  /**
   * Validate a fieldset
   * @param {HTMLCollection|HTMLFormControlsCollection|Object|Array} fieldset
   * @return {boolean}
   */
  validate(fieldset) {
    let isValid    = false;
    let validation = {};

    for (let prop in fieldset) {
      if (fieldset.hasOwnProperty(prop)) {
        let element      = fieldset[prop];
        isValid          = this.isValidInput(element);
        validation[prop] = isValid;
      }
    }
    return validation;
  }

  /**
   * Validates form input
   * @param {HTMLInputElement} input
   * @return {boolean}
   */
  isValidInput(input) {
    let isValid = true;
    let min     = input.min || 1;
    let max     = input.max || 1024;

    if (min > input.value.length || input.value.length > max) {
      isValid = false;
    }

    if (input.hasAttribute('pattern')) {
      isValid = input.value.match(input.pattern) !== null;
    }

    return isValid;
  }
}