/**
 * HttpRequestController
 * @description - Abstract controller represents a typical http request
 * @class
 */
export class HttpRequestController {
  /**
   * @constructor
   * @param model
   * @param view
   */
  constructor(model, view) {
    this.model = model;
    this.view  = view;
  }

  error(msg, data) {
    window.console.log('[ ERROR ] ' + msg + ' => [' + data + ']');
  }

  parseError(event) {
    _self.error('EVENT ERROR ->', event);
    return _self;
  }

  transferProgress(event) {
    if (event.lengthComputable) {
      window.console.log('[ TRANSFER ] ' + ((100) * (event.loaded / event.total)) + '% Complete.');
    }
    return this;
  }

  /**
   * http transfer complete
   * @param {ProgressEvent} event
   */
  transferComplete(event) {
    this.responseHandler(this.model.request.responseText);
    return this;
  }

  setResponseHandler(fn) {
    this.responseHandler = fn;
    return this;
  }

  bindEvents() {

    this.model.request.addEventListener('progress', this.transferProgress.bind(this));
    this.model.request.addEventListener('load', this.transferComplete.bind(this));
    this.model.request.addEventListener('error', this.parseError);

    return this;

  }

  executeRequest() {

    if (!this.model.isValid().isSuccess) {
      return;
    }

    this.model.request.open(this.model.requestMethod, this.model.requestUrl);

    this.model.requestMethod === 'POST'
      ? this.model.request.send(this.model.requestData)
      : this.model.request.send();

    return this;
  }

}
