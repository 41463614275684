import {HtmlView} from './HtmlView.src';

export class FormView extends HtmlView {
  /**
   * @constructor
   * @param {Object} [config]
   * @property {HTMLFormElement} form
   * @property {Array<HTMLFormElement>} requiredElements
   */
  constructor(config) {
    super(config);
    this.form             = null;
    this.requiredElements = [];
  }

  /**
   * finds and sets form element
   * @param scope
   * @return {FormView}
   */
  setForm(scope) {
    scope     = scope || this.scope;
    this.form = scope.querySelector('form');
    return this;
  }

  /**
   * finds and sets required form elements
   * @param {HTMLElement} [scope]
   * @return {FormView}
   */
  setRequiredElements(scope) {
    scope                 = scope || this.scope;
    let elements          = this.form.elements || scope.querySelector('form').elements;
    this.requiredElements = Array.prototype.filter.call(elements, element => element.required);
    return this;
  }

  /**
   * Toggles error on form element
   * @param {HTMLFormElement} element
   * @param {Boolean} isValid
   * @return {FormView}
   */
  toggleError(element, isValid) {
    isValid = isValid || element.classList.contains('error');

    if (isValid) {

      element.classList.remove('error');
      element.style.borderColor     = '#CCC';
      element.style.backgroundColor = '';

      element.setCustomValidity(isValid ? '' : 'Invalid format');
    }
    else {

      element.classList.add('error');
      element.style.borderColor     = 'red';
      element.style.backgroundColor = 'pink';

      element.setCustomValidity(isValid ? '' : 'Invalid format');
    }

    return this;
  }

  /**
   * gets list of required form elements
   * @return {Array<HTMLFormElement>}
   */
  getRequiredElements() {
    return this.requiredElements;
  }
}