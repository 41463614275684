import {FormModel}        from './models/FormModel';
import {HttpRequestModel} from './models/HttpRequestModel.src';

export class AjaxModalModel extends FormModel {
  /**
   * @param config
   */
  constructor(config) {
    super(config);
    this.HttpRequest = HttpRequestModel;
  }

}
