/**
 * HttpRequestModel
 * @description - Abstract model represents a typical http request
 * @class
 */
export class HttpRequestModel {
  /**
   * @constructor
   */
  constructor(config) {
    this.request       = new XMLHttpRequest();
    this.requestData   = config.data || {};
    this.requestMethod = config.method || 'GET';
    this.requestUrl    = config.url || config.href || null;
    this.responseData  = {};
  }

  setRequestData(data) {
    this.requestData = data;
    return this;
  }

  /**
   * Build a request header
   * @param {String} key
   * @param {String} value
   * @return {HttpRequestModel}
   */
  setRequestHeader(key, value) {
    this.request.setRequestHeader(key, value);
    return this;
  }

  setResponseData(data) {
    this.responseData = data;
    return this;
  }

  getResponseData() {
    return this.responseData;
  }

  /**
   * @return {Object<isSuccess, msg>} status
   */
  isValid() {
    var status = {
      isSuccess: false
      , msg    : ''
    };

    if (this.requestUrl === null) {
      status.msg = 'No url provided.';
    }
    else {
      status.isSuccess = true;
    }

    return status;
  }
}
